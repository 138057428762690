<template>
  <div class="w-sm-100 w-xs-100">
    <div class="announcement-content">
          <span class="announcement-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
          </span>
          <span class="announcement-text">Announcement: <div class="announcement-message">We are discontinuing Trading service in Transcrypt. From now you can only send money or cashout with Transcrypt. 
            If you have balance in trading please withdraw. In case if you need support contact <b class="text-success">support@transcryptglobal.com</b>
          </div></span>
        </div>
    <!--Header-->
    <!--    <vs-row vs-w="12"-->
    <!--            style="border-radius: 10px;border:1px solid black;padding:10px;background-color: #ffffff;color:#000000">-->
    <!--      <vs-col vs-type="flex" vs-align="center" vs-w="6">-->
    <!--        Welcome to TransCrypt !-->
    <!--      </vs-col>-->
    <!--      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="6">-->
    <!--        <div style="display:flex;flex-direction: column">-->
    <!--          <span>Your crypto value</span>-->
    <!--          <span>usd 100</span>-->
    <!--        </div>-->
    <!--      </vs-col>-->
    <!--    </vs-row>-->

    <!--Content-->
    <vs-row vs-w="12">
      <!--Crypto List-->
      <vs-col vs-lg="5" vs-xs="12" vs-sm="12" vs-md="12" class="p-1" v-if="cryptoBalancesAndHoldings">
        <!--Crypto List Header-->
        <vs-row class="white-card-xs mt-2 mb-2 w-100 p-1" vs-type="flex">
          <vs-col vs-type="flex" vs-w="4" vs-justify="center" vs-align="center">
            <div class="text-medium fw-600">Crypto</div>
          </vs-col>
          <vs-col vs-type="flex" vs-w="5" vs-justify="center" vs-align="center">
            <div class="text-medium fw-600">Market Rate (SGD)</div>
          </vs-col>
          <vs-col vs-type="flex" vs-w="3" vs-justify="flex-start" vs-align="center">
            <div class="text-medium fw-600">Holdings</div>
          </vs-col>
        </vs-row>
        <!--List of Crypto-->
        <div v-for="item in cryptoBalancesAndHoldings" :key="item.ccy">
          <vs-row class="white-card-md mt-2 mb-2 w-100 p-1 pointer" vs-type="flex" @click.native="selectCryptoData(item)" :class="{ 'white-card-md-highlighted' : selectedCrypto && (item.ccy ===  selectedCrypto.ccy) }">
            <vs-col vs-type="flex" vs-w="2" vs-justify="center" vs-align="center">
              <cryptoicon :symbol="item.ccy" size="32" class="m-1"/>
            </vs-col>
            <vs-col vs-type="flex" vs-w="3" vs-justify="flex-start" vs-align="center">
              <vs-row>
                <vs-col>
                  <div class="text-medium fw-600 trading-text-black">{{ item.ccy }}</div>
                </vs-col>
                <vs-col>
                  <div class="text-medium" v-if="item.ccy === 'BTC'">Bitcoin</div>
                  <div class="text-medium" v-else-if="item.ccy === 'ETH'">Ethereum</div>
                  <div class="text-medium" v-else-if="item.ccy === 'USDT'">Tether</div>
                  <div class="text-medium" v-else-if="item.ccy === 'USDC'">USD Coin</div>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col vs-type="flex" vs-w="4" vs-justify="flex-start" vs-align="center">
              <vs-row>
                <vs-col>
                  <div class="text-small text-success"> Buy {{ formatFiatPrice(item.buy_rate) }} </div>
                </vs-col>
                <vs-col>
                  <div class="text-small trading-text-black"> Sell {{ formatFiatPrice(item.sell_rate) }} </div>
                </vs-col>
              </vs-row>
            </vs-col>
            <vs-col vs-type="flex" vs-w="3" vs-justify="center" vs-align="center">
              <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                <vs-col>
                  <div class="text-small fw-600 trading-text-black">{{ item.ccy }}
                    <span v-if="item.ccy.toUpperCase() !== 'USDT'">{{formatCryptoPrice(item.bal_amt)}}</span>
                    <span v-else>{{ formatFiatPrice(item.bal_amt)}}</span>
                  </div>
                </vs-col>
                <vs-col>
                  <div class="text-small">{{item.trade_ccy}} {{formatFiatPrice(item.port_val)}}</div>
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
        </div>
        <!--Promotion image-->
<!--        <vs-row>-->
<!--          <img src="/assets/trading-assets/TransCrypt-dashboard-spend10-v2.jpg" style="width: 100%" class="pointer" @click="goToPromotionPage()">-->
<!--        </vs-row>-->
      </vs-col>


      <!--Contents about crypto-->
      <vs-col vs-lg="7" vs-xs="12" vs-sm="12" vs-md="12" class="p-1 trading-crypto-more-data">
        <vs-row class="white-card-md mt-2 mb-2 w-100 p-1" vs-type="flex" vs-align="center" v-if="selectedCrypto">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="7" vs-sm="12">

            <vs-row vs-type="flex" vs-justify="space-around" vs-align="center" :class="[windowInnerWidth < 900 ? 'p-1' : '']">
              <div class="trading-flex">
                <cryptoicon :symbol="selectedCrypto && selectedCrypto.ccy" size="24"></cryptoicon>
                <div class="trading-flex-column ml-1">
                  <div class="text-small fw-600 trading-text-black">
                    {{selectedCrypto && selectedCrypto.ccy.toUpperCase()}}
                  </div>
                  <div class="text-small" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'BTC'">
                    Bitcoin
                  </div>
                  <div class="text-small" v-else-if="selectedCrypto && selectedCrypto.ccy === 'ETH'">
                    Ethereum
                  </div>
                  <div class="text-small" v-else-if="selectedCrypto && selectedCrypto.ccy === 'USDT'">
                    Tether
                  </div>
                </div>
              </div>

              <div class="trading-flex">
                <div class="trading-flex-column">
                  <div class="text-small">
                    Holding
                  </div>
                  <div class="text-small fw-600 trading-text-black">
                    {{selectedCrypto.ccy.toUpperCase()}}
                    <span v-if="selectedCrypto.ccy.toUpperCase() !== 'USDT'">
                      {{ formatCryptoPrice(selectedCrypto.bal_amt)}}
                    </span>
                    <span v-else>
                      {{formatFiatPrice(selectedCrypto.bal_amt)}}
                    </span>
                  </div>
                </div>
                <div class="text-small mr-1 ml-1">
                  =
                </div>
                <div class="trading-flex-column">
                  <div class="text-small">
                    Value
                  </div>
                  <div class="text-small fw-600 trading-text-black">
                    {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.port_val)}}
                  </div>
                </div>
              </div>
            </vs-row>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-lg="5" vs-sm="12">
            <!-- <button class="trading-flex-column btn-trading-positive w-100 mr-1 pointer"
                    @click="goToBuyPage()">
              <div class="text-small">
                Buy
              </div>
              <div class="text-xsmall">
                {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.buy_rate)}}
              </div>
            </button> -->

            <button class=" btn-trading-negative w-100 pointer text-center"
                    :class="{'btn-disabled' : selectedCrypto && !selectedCrypto.port_val }"
                    :disabled="selectedCrypto && !selectedCrypto.port_val"
                    @click="goToSellPage()">
              <div class="text-small">
                Sell
              </div>
              <div class="text-xsmall">
                {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.sell_rate)}}
              </div>
            </button>

          </vs-col>
        </vs-row>


        <!--CHART-->
        <vs-row vs-type="flex" class="white-card-lg mt-2 mb-2 w-100" v-if="selectedCrypto">

          <vs-row class="m-1" vs-justify="flex-start" vs-w="12">
<!--            <div class="text-small p-1 fw-600">-->
<!--              Market Overview-->
<!--            </div>-->
<!--            <div class="text-small p-1" style="border-left:1px solid #D8D8D8">-->
<!--              Transaction Details-->
<!--            </div>-->
          </vs-row>
          <div class="w-100">
            <vs-row class="pl-3 pr-3" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <div v-for="item in chartDuration" :key="item" class="text-small m-1 p-1 pointer" :class="{'duration-active' : item === selectedChartDuration}">
                <span @click="selectChartDuration(item)" v-if="item === 'hour'">1H</span>
                <span @click="selectChartDuration(item)" v-else-if="item === 'day'">1D</span>
                <span @click="selectChartDuration(item)" v-else-if="item === 'week'">1W</span>
                <span @click="selectChartDuration(item)" v-else-if="item === 'month'">1M</span>
                <span @click="selectChartDuration(item)" v-else-if="item === 'year'">1Y</span>
              </div>
            </vs-row>
            <!--TODO show chart if the windowinnerwidth is > 900 and chart data has been fetch (use async await)-->
            <line-chart v-if="windowInnerWidth > 900 && chartData" :chartData="chartData" ref="linechart" :options="options" class="w-100 p-2"/>
          </div>
        </vs-row>
      </vs-col>

    </vs-row>

    <!--    POPUP IN SMALL SCREEN-->
<!--    <vs-popup fullscreen title="Details" :active.sync="popupActive">-->

<!--      <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="mb-2" v-if="selectedCrypto">-->

<!--          <cryptoicon :symbol="selectedCrypto.ccy" size="28"></cryptoicon>-->

<!--          <div class="text-medium fw-600 trading-text-black m-1">-->
<!--            {{selectedCrypto.ccy.toUpperCase()}}-->
<!--          </div>-->
<!--          <div class="text-medium fw-600 trading-text-black" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'BTC'">-->
<!--           ( Bitcoin )-->
<!--          </div>-->
<!--        <div class="text-medium fw-600 trading-text-black" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'ETH'">-->
<!--          ( Ethereum )-->
<!--        </div>-->
<!--        <div class="text-medium fw-600 trading-text-black" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'USDT'">-->
<!--          ( Tether )-->
<!--        </div>-->
<!--      </vs-row>-->

<!--      <vs-row vs-type="flex" vs-w="12" class="white-card-md-highlighted mb-2" vs-align="center" v-if="selectedCrypto">-->
<!--        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="center"-->
<!--                class="trading-border-right-green p-1">-->
<!--          <div class="trading-flex-column text-left">-->
<!--            <div class="text-success text-medium">-->
<!--              Buying Price-->
<!--            </div>-->
<!--            <div class="text-large fw-700">-->
<!--              {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.buy_rate)}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </vs-col>-->

<!--        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end" vs-align="center" class="p-1">-->
<!--          <div class="trading-flex-column text-right">-->
<!--            <div class="text-error text-medium">-->
<!--             Selling Price-->
<!--            </div>-->
<!--            <div class="text-large fw-700">-->
<!--              {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.sell_rate)}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </vs-col>-->
<!--      </vs-row>-->

<!--      <vs-row vs-type="flex" class="mb-2" vs-w="12" v-if="selectedCrypto">-->
<!--      &lt;!&ndash;show chart if the popup is active and data has been fetched (use async await)&ndash;&gt;-->
<!--        <line-chart v-if="popupActive && chartData" :chartData="chartData" :options="options" class="w-100 p-2"/>-->
<!--        <div class="trading-flex">-->
<!--          <div v-for="item in chartDuration" :key="item" class="text-small m-1 p-1 pointer" :class="{'duration-active' : item === selectedChartDuration}">-->
<!--            <span @click="selectChartDuration(item)" v-if="item === 'hour'">1H</span>-->
<!--            <span @click="selectChartDuration(item)" v-else-if="item === 'day'">1D</span>-->
<!--            <span @click="selectChartDuration(item)" v-else-if="item === 'week'">1W</span>-->
<!--            <span @click="selectChartDuration(item)" v-else-if="item === 'month'">1M</span>-->
<!--            <span @click="selectChartDuration(item)" v-else-if="item === 'year'">1Y</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </vs-row>-->

<!--      <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="space-between" class="white-card-md-highlighted p-1 mb-2" v-if="selectedCrypto">-->

<!--          <div class="trading-flex-column text-left">-->
<!--            <div class="text-medium">-->
<!--              Crypto Owned-->
<!--            </div>-->
<!--            <div class="text-medium fw-700">-->
<!--              {{selectedCrypto.ccy.toUpperCase()}}-->
<!--              <span v-if="selectedCrypto.ccy.toUpperCase() !== 'USDT'">{{formatCryptoPrice(selectedCrypto.bal_amt)}}</span>-->
<!--              <span v-else>{{formatFiatPrice(selectedCrypto.bal_amt)}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text-large mr-1 ml-1 text-success fw-700">-->
<!--            =-->
<!--          </div>-->
<!--          <div class="trading-flex-column text-right">-->
<!--            <div class="text-medium">-->
<!--              Current Value-->
<!--            </div>-->
<!--            <div class="text-medium fw-700">-->
<!--              {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.port_val)}}-->
<!--            </div>-->
<!--          </div>-->
<!--      </vs-row>-->

<!--      <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" v-if="selectedCrypto">-->
<!--        <vs-col vs-w="6" class="p-1">-->
<!--          <div class=" btn-trading-positive  pointer" @click="goToBuyPage()">-->
<!--            <div class="text-medium">-->
<!--              Buy {{selectedCrypto.ccy}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </vs-col>-->
<!--        <vs-col vs-w="6" class="p-1">-->
<!--          <div class=" btn-trading-negative pointer" @click="goToSellPage()">-->
<!--            <div class="text-medium">-->
<!--              Sell {{selectedCrypto.ccy}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </vs-col>-->
<!--      </vs-row>-->
<!--    </vs-popup>-->


    <!--    POPUP IN SMALL SCREEN (NOT USING VS-POPUP)-->
    <div class="trading-popup-crypto-content-container" v-if="popupActive">
      <vs-row vs-type="flex" vs-justify="space-between" vs-align="center" class="mb-2" v-if="selectedCrypto">
       <div class="pointer" @click="closePopUp()">
         <arrow-left-icon size="1.5x"></arrow-left-icon>
       </div>
        <img
            src="/assets/trading-assets/TransCrypt-logo-symbol.png" alt="TransCrypt-logo" class="md-logo"/>

      </vs-row>

      <!--Crypto name and symbol-->
      <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center" class="mb-2 pl-2 pr-2" v-if="selectedCrypto">

        <cryptoicon :symbol="selectedCrypto.ccy" size="28"></cryptoicon>

        <div class="text-medium fw-600 trading-text-black m-1">
          {{selectedCrypto.ccy.toUpperCase()}}
        </div>
        <div class="text-medium fw-600 trading-text-black" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'BTC'">
          ( Bitcoin )
        </div>
        <div class="text-medium fw-600 trading-text-black" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'ETH'">
          ( Ethereum )
        </div>
        <div class="text-medium fw-600 trading-text-black" v-if="selectedCrypto && selectedCrypto.ccy.toUpperCase() === 'USDT'">
          ( Tether )
        </div>
      </vs-row>

      <!--Crypto buying and selling price-->
      <vs-row vs-type="flex" vs-w="12" class="white-card-md mb-2 pl-1 pr-1" vs-align="center" v-if="selectedCrypto">
        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="center"
                class="trading-border-right p-1">
          <div class="trading-flex-column text-left">
            <div class="text-success text-medium">
              Buying Price
            </div>
            <div class="text-large fw-700">
              {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.buy_rate)}}
            </div>
          </div>
        </vs-col>

        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end" vs-align="center" class="p-1">
          <div class="trading-flex-column text-right">
            <div class="text-error text-medium">
              Selling Price
            </div>
            <div class="text-large fw-700">
              {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.sell_rate)}}
            </div>
          </div>
        </vs-col>
      </vs-row>

      <!--Crypto chart-->
      <vs-row vs-type="flex" class="mb-2 trading-popup-chart-container" vs-w="12" v-if="selectedCrypto">
        <!--show chart if the popup is active and data has been fetched (use async await)-->
        <line-chart v-if="popupActive && chartData" :chartData="chartData" :options="options" class="w-100 mb-1"/>
        <div class="trading-flex-center w-100 trading-popup-chart-duration-border-top">
          <div v-for="item in chartDuration" :key="item" class="text-small m-1 p-1 pointer" :class="{'duration-active' : item === selectedChartDuration}">
            <span @click="selectChartDuration(item)" v-if="item === 'hour'">1H</span>
            <span @click="selectChartDuration(item)" v-else-if="item === 'day'">1D</span>
            <span @click="selectChartDuration(item)" v-else-if="item === 'week'">1W</span>
            <span @click="selectChartDuration(item)" v-else-if="item === 'month'">1M</span>
            <span @click="selectChartDuration(item)" v-else-if="item === 'year'">1Y</span>
          </div>
        </div>
      </vs-row>

      <!--Crypto Holdings-->
      <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="space-between" class="white-card-md-highlighted pl-2 pr-2 pt-1 pb-1 mb-2" v-if="selectedCrypto">

        <div class="trading-flex-column text-left">
          <div class="text-medium">
            Crypto Owned
          </div>
          <div class="text-medium fw-700">
            {{selectedCrypto.ccy.toUpperCase()}}
            <span v-if="selectedCrypto.ccy.toUpperCase() !== 'USDT'">{{formatCryptoPrice(selectedCrypto.bal_amt)}}</span>
            <span v-else>{{formatFiatPrice(selectedCrypto.bal_amt)}}</span>
          </div>
        </div>
        <div class="text-large mr-1 ml-1 text-success fw-700">
          =
        </div>
        <div class="trading-flex-column text-right">
          <div class="text-medium">
            Current Value
          </div>
          <div class="text-medium fw-700">
            {{selectedCrypto.trade_ccy.toUpperCase()}} {{ formatFiatPrice(selectedCrypto.port_val)}}
          </div>
        </div>
      </vs-row>

      <!--Crypto Buy and Sell Button-->
      <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center" v-if="selectedCrypto">
        <!-- <vs-col vs-w="6" class="p-1">
          <div class=" btn-trading-positive  pointer" @click="goToBuyPage()">
            <div class="text-medium">
              Buy {{selectedCrypto.ccy}}
            </div>
          </div>
        </vs-col> -->
        <vs-col vs-w="12" class="p-1 text-center">
          <div class=" btn-trading-negative pointer"
               :class="{'btn-disabled' : selectedCrypto && !selectedCrypto.port_val }"
               :disabled="selectedCrypto && !selectedCrypto.port_val"
               @click="goToSellPage()">
            <div class="text-medium">
              Sell {{selectedCrypto.ccy}}
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>

    <vs-row vs-w="12" vs-type="flex" vs-justify="flex-end">
      <div class="text-small text-success">
        This service is only available for Singapore-based users.
      </div>
    </vs-row>
  </div>

</template>
<script>


import LineChart from './Chart/TradingChart.js'
import {mapActions, mapGetters, mapState} from "vuex";
import Vue from "vue";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import {ArrowLeftIcon} from 'vue-feather-icons'

export default {
  name: 'trading-main-page',
  components: {LineChart,ArrowLeftIcon},
  data : () => ({

      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      cryptoList: [
        {
          cryptoCode: 'BTC',
          cryptoName: 'Bitcoin',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        }, {
          cryptoCode: 'ETH',
          cryptoName: 'Ethereum',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          cryptoCode: 'USDT',
          cryptoName: 'Tether',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          cryptoCode: 'LTC',
          cryptoName: 'Litecoin',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          cryptoCode: 'XRP',
          cryptoName: 'Ripple',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        },
        {
          cryptoCode: 'ADA',
          cryptoName: 'Cardano',
          cryptoPrice: 50000,
          fiatCurrency: 'SGD',
        }
      ],


      popupActive: false,

      cryptoTradingExtendDataStyling: false,

      windowInnerWidth: null,
      refreshScrollableArea: null,

      chartDuration : ['hour','day', 'week', 'month', 'year'],
      selectedChartDuration : "",

      selectedCrypto : null,

      chartData : null,
      // options: {
      //   events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   legend: {
      //     display: false,
      //     position: 'top',
      //   },
      //   scales: {
      //     xAxes: [{
      //       gridLines: {
      //         display: false
      //       }
      //     }],
      //     yAxes: [{
      //       gridLines: {
      //         display: false
      //       }
      //     }]
      //   }
      // },
    options : null,
    chartRefreshInterval : null,

    debugConsole : false,
  }),
  computed: {
    ...mapState(["newTradingPriceData"]),
    ...mapGetters(["userCryptoBalanceGetter"]),

    cryptoBalancesAndHoldings()
    {
      let cryptoBalancesAndHoldings = this.userCryptoBalanceGetter && this.userCryptoBalanceGetter;
      // let sortedCryptoBalancesAndHoldings = [...cryptoBalancesAndHoldings]
      return cryptoBalancesAndHoldings
    },
    //for charts
    // chartData() {
    //   return {
    //     labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //     datasets: [
    //       {
    //         label: 'Price',
    //         data: [40, 10, 20, 30, 55, 87, 9, 30, 20, 10, 80, 50],
    //       }
    //     ]
    //   }
    // },
    //for charts
    // options() {
    //   return {
    //     events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     legend: {
    //       display: false,
    //       position: 'top',
    //     },
    //     scales: {
    //       xAxes: [{
    //         gridLines: {
    //           display: false
    //         }
    //       }],
    //       yAxes: [{
    //         gridLines: {
    //           display: false
    //         }
    //       }]
    //     }
    //     // plugins: {
    //     //   tooltip: {
    //     //     // Tooltip will only receive click events
    //     //     events: ['mousemove'],
    //     //     enabled:true,
    //     //     callbacks:{
    //     //       labelColor: function(context) {
    //     //         return {
    //     //           borderColor: 'rgb(0, 0, 255)',
    //     //           backgroundColor: 'rgb(255, 0, 0)',
    //     //           borderWidth: 2,
    //     //           borderDash: [2, 2],
    //     //           borderRadius: 2,
    //     //         };
    //     //       },
    //     //       labelTextColor: function(context) {
    //     //         return '#543453';
    //     //       }
    //     //     }
    //     //
    //     //   }
    //     // }
    //   }
    //
    // },
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  created() {
    window.addEventListener("resize", () => {this.windowInnerWidthHandler()});
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {this.windowInnerWidthHandler()} );
    clearInterval(this.chartRefreshInterval);
    console.warn("CHART REFRESH INTERVAL CLEARED", this.chartRefreshInterval);
  },
  watch: {
    chartData : function(newVal, oldVal)
    {
      if(this.debugConsole)  console.log("New Val in main page", newVal);
      if(this.debugConsole)   console.log("Old Val in main page", oldVal);

    },
    selectedChartDuration : function(newVal, oldVal)
    {
      if(this.debugConsole) console.log("Old Chart Duration", oldVal);
      if(this.debugConsole) console.log("New Chart Duration", newVal);

    }
  },
  mounted() {
    // let gradientFill = this.$refs.canvas.createLinearGradient(500, 0, 100, 0);
    // gradientFill.addColorStop(0, "rgba(128, 182, 244, 0.6)");
    // gradientFill.addColorStop(1, "rgba(244, 144, 128, 0.6)");

    //get the trading-crypto-more-data element and its styling
    this.windowInnerWidth = window.innerWidth;


    let document = window.document.querySelector('.trading-crypto-more-data');
    let styling = window.getComputedStyle(document);

    this.cryptoTradingExtendDataStyling = styling;
    if(this.debugConsole) console.log("Crypto extend data styling", this.cryptoTradingExtendDataStyling);

    //call the balances API again to get the latest data
    this.getUserBalances().then(()=> {
      //get the crypto balances
      if(this.debugConsole)  console.log("Get crypto balances & holdings in main page", this.cryptoBalancesAndHoldings)

      //select the first crypto that's on the top of the list
      this.selectedCrypto = this.cryptoBalancesAndHoldings && this.cryptoBalancesAndHoldings[0];
      if(this.debugConsole)  console.log("Selected Crypto", this.selectedCrypto);
      //select the hour as the default duration
      this.selectedChartDuration = this.chartDuration[0];

      if(this.selectedCrypto)
      {
        // let payload = {};
        // payload.ccy = this.selectedCrypto.ccy;
        // payload.duration = this.selectedChartDuration; //default


        // this.getPriceFeed(payload).then(res => {
        //   console.log("Get Price feed in main portfolio page when mounted", res.data);
        //
        //   // let data = [...res.data];
        //   // console.log("Date before reverse", data);
        //   // let newReverseData = data.reverse();
        //   // console.log("Data after reverse", newReverseData);
        //   //
        //   // //chart Data
        //   // this.chartData = {
        //   //    labels: newReverseData.map((el,index) => index),
        //   // datasets: [
        //   //   {
        //   //     label: `${this.selectedCrypto.ccy} price`,
        //   //     data: newReverseData.map((el,index) => el.buy_rt)
        //   //   }
        //   // ]
        //   // }
        //
        //   this.implementNewChartData(res.data);
        //
        //
        //
        // }).catch(err => {
        //   console.log("Error in getting price feed in main portfolio page", err.response);
        // })
        //
        //
        // //test the new price feed
        // this.getPriceFeedNew(payload).then(res => {
        //   console.log("New Price Feed", res.data);
        //
        //   this.showDataOnChart();
        //
        // }).catch(err => {
        //   console.log("Error in getting new price feed", err.response);
        // })

        this.showDataOnChart();

        //call the interval function, based on the chart duration
        if(this.selectedChartDuration === 'hour')
        {
          //clear the interval
          clearInterval(this.chartRefreshInterval);
          if(this.debugConsole)   console.log("Start interval for chart duration hour")
          if(this.debugConsole)  console.log("this chart refresh interval before (hour)", this.chartRefreshInterval);

          this.chartRefreshInterval = setInterval(() => this.updatePriceFeedOnInterval(), 300000); // 5 mins

          if(this.debugConsole)  console.log("this chart refresh interval after (hour)", this.chartRefreshInterval);
        }
        else if(this.selectedChartDuration === 'day')
        {
          //clear the interval
          clearInterval(this.chartRefreshInterval);
          if(this.debugConsole)  console.log("Start interval for chart duration day");
          if(this.debugConsole) console.log("this chart refresh interval before (day)", this.chartRefreshInterval);
          this.chartRefreshInterval = setInterval(() => this.updatePriceFeedOnInterval(), 480000); // 8 mins

          if(this.debugConsole)  console.log("this chart refresh interval after (day)", this.chartRefreshInterval);
        }
        else if(this.selectedChartDuration === 'week')
        {
          //clear the interval
          clearInterval(this.chartRefreshInterval);
          if(this.debugConsole)  console.log("Start interval for chart duration week");
          if(this.debugConsole)  console.log("this chart refresh interval before (week)", this.chartRefreshInterval);
          this.chartRefreshInterval = setInterval(() => this.updatePriceFeedOnInterval(), 600000); // 10 mins

          if(this.debugConsole)  console.log("this chart refresh interval after (week)", this.chartRefreshInterval);
        }
        else if(this.selectedChartDuration === 'month')
        {
          //clear the interval
          clearInterval(this.chartRefreshInterval);
          if(this.debugConsole)  console.log("Start interval for chart duration month");
          if(this.debugConsole)  console.log("this chart refresh interval before (month)", this.chartRefreshInterval);
          this.chartRefreshInterval = setInterval(() => this.updatePriceFeedOnInterval(), 900000); // 15 mins

          if(this.debugConsole)  console.log("this chart refresh interval after (month)", this.chartRefreshInterval);
        }
        else if(this.selectedChartDuration === 'year')
        {
          //clear the interval
          clearInterval(this.chartRefreshInterval);
          if(this.debugConsole)  console.log("Start interval for chart duration year");
          if(this.debugConsole)  console.log("this chart refresh interval before (year)", this.chartRefreshInterval);
          this.chartRefreshInterval = setInterval(() => this.updatePriceFeedOnInterval(), 1800000); // 30 mins

          if(this.debugConsole)  console.log("this chart refresh interval after (year)", this.chartRefreshInterval);
        }


      }
    }) ;


    },
  methods: {
    ...mapActions(["getUserBalances","getPriceFeed","getPriceFeedNew"]),

    windowInnerWidthHandler() {
      this.windowInnerWidth = window.innerWidth;

      //if window width > 900 px, disable the popup
      if(this.windowInnerWidth > 900)
      {
        this.popupActive = false;
      }
    },
    // goToBuyPage() {

    //   if(this.debugConsole)  console.log("Selected Crypto before buy page", this.selectedCrypto && this.selectedCrypto.ccy.toUpperCase());

    //   if(this.selectedCrypto && this.selectedCrypto.ccy)
    //   {
    //     this.$router.push(`/trading/buy/${this.selectedCrypto.ccy}`);
    //   }

    // },
    goToSellPage() {
      if(this.debugConsole)  console.log("Selected Crypto before sell page", this.selectedCrypto && this.selectedCrypto.ccy.toUpperCase());

      if(this.selectedCrypto && this.selectedCrypto.ccy)
      {
        this.$router.push(`/trading/sell/${this.selectedCrypto.ccy}`);
      }

    },

    //TODO click on one of the crypto will display the data for that crypto (chart, holdings,price, etc)
    selectCryptoData(item) {
      if(this.debugConsole)  console.log("Selected Crypto", item);

      this.selectedCrypto = item;

      // let payload = {};
      // payload.ccy = this.selectedCrypto.ccy;
      // payload.duration = this.selectedChartDuration;

      // this.getPriceFeed(payload).then(res => {
      //   console.log("Get Price feed in main portfolio page when selecting crypto", res.data);
      //
      //   // let data = [...res.data];
      //   // console.log("Date before reverse", data);
      //   // let newReverseData = data.reverse();
      //   // console.log("Data after reverse", newReverseData);
      //   //
      //   // // //chart Data
      //   // this.chartData = {
      //   //   labels: newReverseData.map((el,index) => index),
      //   //   datasets: [
      //   //     {
      //   //       label: `${this.selectedCrypto.ccy} price`,
      //   //       data: newReverseData.map((el,index) => el.buy_rt)
      //   //     }
      //   //   ]
      //   // }
      //
      //   this.implementNewChartData(res.data);
      //
      //
      // }).catch(err => {
      //   console.log("Error in getting price feed in main portfolio page when selecting crypto", err.response);
      // })


      if(this.windowInnerWidth <= 900)
      {
        this.popupActive = true;
      } else {
        this.popupActive = false;
      }

      //update data
      this.showDataOnChart();

    },

    selectChartDuration(item){

      this.selectedChartDuration = item;
      if(this.debugConsole)  console.log("Chart Duration", this.selectedChartDuration);

      this.showDataOnChart();
    },



    showDataOnChart(){
      //check crypto and duration
      let crypto = this.selectedCrypto.ccy.toLowerCase();
      let duration = this.selectedChartDuration;

      //check if the state has already the data
      //if no data, call the API. If has data, get data from getters or state

      if(this.debugConsole)  console.log("this.selectedCrypto new Price feed", crypto)
      if(this.debugConsole)  console.log("this duration new price feed", duration);
      if(this.debugConsole)  console.log("this.newTradingPriceData", this.newTradingPriceData);

      let priceFeedData = this.newTradingPriceData && this.newTradingPriceData[crypto][duration];
      if(this.debugConsole)  console.log("Price Feed Data", priceFeedData);

      if(priceFeedData.length > 0)
      {
        if(this.debugConsole)  console.log("Price Feed data exist");

        let beforeReverseData = [...priceFeedData];
        if(this.debugConsole)  console.log("Date before reverse", priceFeedData);
        let newReverseData = beforeReverseData.reverse();
        if(this.debugConsole)  console.log("Data after reverse", newReverseData);

        // update chart Data
        this.chartData = {
          labels: newReverseData.map((el,index) => el.rt_dt),
          datasets: [
            {
              label: `${this.selectedCrypto.ccy} price`,
              data: newReverseData.map((el,index) => el.buy_rt)
            }
          ]
        }

        this.options = {
          events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'top',
            enabled : false,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks : {
                maxRotation: 0,
                minRotation: 0,
                maxTicksLimit : 8,
                fontSize : 10,
                callback : (value, index, values) =>
                {
                  // console.log("this selected chart duration inside callback", this.selectedChartDuration);
                  if(this.selectedChartDuration === 'hour'){
                    return format(new Date(value), 'p');
                  }
                  else if(this.selectedChartDuration === 'day'){
                    return format(new Date(value), 'p');
                  }
                  else if(this.selectedChartDuration === 'week'){
                    return format(new Date(value), 'dd/MM');
                  }
                  else if(this.selectedChartDuration === 'month'){
                    return format(new Date(value), 'dd/MM');
                  }
                  else {
                    return format(new Date(value), 'MM/yy');
                  }

                }
              }
            }],
            yAxes: [{
              gridLines: {
                display: false
              },
              ticks : {
                callback : (value, index, values) =>
                {
                  return this.formatFiatPrice(value);
                }
              }
            }]
          },
          tooltip : {
            enabled : false,
            callbacks : {
              // labelColor: ((tooltipItem, chart) => {
              //   return {
              //     borderColor: 'rgb(255, 0, 0)',
              //     backgroundColor: 'rgb(255, 0, 0)'
              //   };
              // }),
              // labelTextColor: ((tooltipItem, chart) => {
              //   return '#543453';
              // }),
              yLabel : function(tooltipItem, data)
              {
                console.log("Tooltip Item", tooltipItem);
                return 'hellow'
                // return  format(new Date(data['datasets'][0]['labels'][tooltipItem['index']]),'dd/MM')
              },
              xLabel : function(tooltipItem, data)
              {
                console.log("Tooltip Item", tooltipItem);
                // return `${data['datasets'][0]['data'][tooltipItem['index']]}`
                return 'test-string'
              }
            }
          }
        }



      }
      else
      {
        if(this.debugConsole)   console.log("Price feed data does not exist");
        //call the API to get the data;
        let payload = {};
        payload.ccy = crypto;
        payload.duration = duration;

        this.getPriceFeedNew(payload).then(res => {
          if(this.debugConsole)   console.log("New Price Feed", res.data);

          let priceFeedData  = res.data;

          let beforeReverseData = [...priceFeedData];
          if(this.debugConsole)   console.log("Date before reverse", priceFeedData);
          let newReverseData = beforeReverseData.reverse();
          if(this.debugConsole)   console.log("Data after reverse", newReverseData);

          // update chart Data
          this.chartData = {
            labels: newReverseData.map((el,index) => el.rt_dt),
            datasets: [
              {
                label: `${this.selectedCrypto.ccy} price`,
                data: newReverseData.map((el,index) => el.buy_rt)
              }
            ]
          }

          this.options = {
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'top',
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false
                },
                ticks : {
                  maxRotation: 0,
                  minRotation: 0,
                  maxTicksLimit : 8,
                  fontSize : 10,
                  callback : (value, index, values) =>
                  {
                    // console.log("this selected chart duration inside callback", this.selectedChartDuration);
                    if(this.selectedChartDuration === 'hour'){
                      return format(new Date(value), 'p');
                    }
                    else if(this.selectedChartDuration === 'day'){
                      return format(new Date(value), 'p');
                    }
                    else if(this.selectedChartDuration === 'week'){
                      return format(new Date(value), 'dd/MM');
                    }
                    else if(this.selectedChartDuration === 'month'){
                      return format(new Date(value), 'dd/MM');
                    }
                    else {
                      return format(new Date(value), 'MM/yy');
                    }
                  }
                }
              }],
              yAxes: [{
                gridLines: {
                  display: false
                },
                ticks : {
                  callback : (value, index, values) =>
                  {
                    return this.formatFiatPrice(value);
                  }
                }
              }]
            },
            tooltip : {
              enabled : false,
              callbacks : {
                // labelColor: ((tooltipItem, chart) => {
                //   return {
                //     borderColor: 'rgb(255, 0, 0)',
                //     backgroundColor: 'rgb(255, 0, 0)'
                //   };
                // }),
                // labelTextColor: ((tooltipItem, chart) => {
                //   return '#543453';
                // }),
                yLabel : function(tooltipItem, data)
                {
                  console.warn("Tooltip Item", tooltipItem);
                  // return format(new Date(data['datasets'][0]['labels'][tooltipItem['index']]),'dd/MM')
                  return 'hellow'
                },
                xLabel : function(tooltipItem, data)
                {
                  console.warn("Tooltip Item", tooltipItem);
                  return 'test-string'
                  // return `${data['datasets'][0]['data'][tooltipItem['index']]}`
                  // return tooltipItem.xLabel
                }
              }
            }
          }


        }).catch(err => {
          console.log("Error in getting new price feed", err.response);
        })

      }


    },

    updatePriceFeedOnInterval(){
      if(this.debugConsole) console.log("this chart refresh interval", this.chartRefreshInterval);

      let payload = {};
      payload.ccy = this.selectedCrypto.ccy.toLowerCase();
      payload.duration = this.selectedChartDuration;

      if(this.debugConsole) console.log("Payload on update price feed on interval", payload)
      this.getPriceFeedNew(payload).then(res =>{

      }).catch(err => {

      })
    },
    closePopUp()
    {
      this.popupActive = false;
    },

    goToPromotionPage(){
      this.$router.push('/trading-special-promotions');
    }



  }

}

</script>
<style>


</style>